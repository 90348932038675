<template>
    <div>
        <HeaderB />

        <div class="Bloque1">

            <div class="B1F1">
                <div class="B1F1C1">
                    <div class="B1F1C1_C1">
                        <img src="../../assets/logo1_1.png">
                    </div>
                    <div class="B1F1C1_C2">
                        <p>LABORATORIO CLÍNICO BARRAGAN</p>
                    </div>
                </div>

                <div class="B1F1C2">
                    <div class="B1F1C2_C1">
                        <ul>
                            <li @click="cambiarOpcion('Home')" :class="{ selector: this.opcion == 'Home' }"><a
                                    href="/barragan">Inicio</a></li>
                            <li @click="cambiarOpcion('Nosotros'), redirect('nosotros')"
                                :class="{ selector: this.opcion == 'Nosotros' }">Nosotros
                            </li>
                            <li @click="cambiarOpcion('Servicios')" :class="{ selector: this.opcion == 'Servicios' }"><a
                                    href="/barragan/servicios">Servicios</a></li>
                            <li @click="cambiarOpcion('Certificaciones'), redirect('certificaciones')"
                                :class="{ selector: this.opcion == 'Certificaciones' }">
                                Certificaciones</li>
                            <li @click="cambiarOpcion('Contacto'), redirect('contacto')"
                                :class="{ selector: this.opcion == 'Contacto' }">Contacto
                            </li>
                            <li @click="cambiarOpcion('Privacidad')" :class="{ selector: this.opcion == 'Privacidad' }">
                                <a href="/barragan/politica">Aviso Privacidad</a>
                            </li>
                            <li :class="{ selector: this.opcion == 'Quejas' }">
                                <a href="#" @click="cambiarOpcion('Quejas')">Quejas</a>

                                <div class="wrapper" :class="{ 'extended': opcion == 'Quejas' }">
                                    <div>
                                        <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                                        <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                                        <ol>
                                            <li>Envíanos un mensaje por medio de este botón o al correo <a
                                                    href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                                            </li>
                                            <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja
                                                recibe y
                                                analiza las causas que la originaron si procede o no en un plazo máximo
                                                de
                                                10 días hábiles.</li>
                                            <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.</li>
                                        </ol>
                                        <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>

                                        <button @click="cambiarOpcion('Home')">Salir</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>

        <div>
            <div class="politica-cont">
                <div class="titulo-pol">AVISO DE PRIVACIDAD</div>
                <p class="texto-pol">
                    LABORATORIO BARRAGAN S.A. DE C.V., mejor conocido como LABORATORIO CLINICO BARRAGAN, con
                    domicilio en calle GARMENDIA 80 ENTRE VERACRUZ Y NAYARIT, colonia SAN BENITO, ciudad Hermosillo,
                    municipio o delegación Hermosillo, c.p. 83190, en la entidad de Sonora, país México, y portal de
                    internet NO
                    DISPONIBLE, es el responsable del uso y protección de sus datos personales, y al respecto le
                    informamos lo
                    siguiente:
                </p>

                <p class="texto-pol"><b>¿Para qué fines utilizaremos sus datos personales?</b></p>

                <p class="texto-pol text3">De manera adicional, utilizaremos su información personal para las siguientes
                    finalidades secundarias que no son
                    necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor
                    atención:
                </p>

                <p class="texto-pol lista-pol">● Para gestionar, otorgar y dar seguimiento a los servicios comerciales
                    que ha solicitado.</p>
                <p class="texto-pol lista-pol">● Tramites de facturación.</p>
                <p class="texto-pol lista-pol">● Datos contenidos en los informes de prueba emitidos.</p>
                <p class="texto-pol lista-pol">● Verificar y confirmar su identidad.</p>
                <p class="texto-pol lista-pol">● Enviar correo con informes.</p>
                <p class="texto-pol lista-pol">● Llamadas para solicitud de datos.</p>
                <p class="texto-pol lista-pol">● Mercadotecnia o publicitaria</p>
                <p class="texto-pol lista-pol">● Prospección comercial</p>

                <p class="texto-pol text4">En caso de que no desee que sus datos personales sean tratados para estos
                    fines secundarios, desde este momento
                    usted nos puede comunicar lo anterior a través del siguiente mecanismo:</p>

                <p class="texto-pol">Correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol text4">La negativa para el uso de sus datos personales para estas finalidades no
                    podrá ser un motivo para que le neguemos
                    los servicios y productos que solicita o contrata con nosotros.</p>

                <p class="texto-pol"><b>¿Qué datos personales utilizaremos para estos fines?</b></p>

                <p class="texto-pol text4">Para llevar a cabo las finalidades descritas en el presente aviso de
                    privacidad, utilizaremos los siguientes datos
                    personales:</p>

                <p class="texto-pol lista-pol">● Nombre</p>
                <p class="texto-pol lista-pol">● Estado Civil</p>
                <p class="texto-pol lista-pol">● Registro Federal de Contribuyentes(RFC)</p>
                <p class="texto-pol lista-pol">● Clave única de Registro de Población (CURP)</p>
                <p class="texto-pol lista-pol">● Lugar de nacimiento</p>
                <p class="texto-pol lista-pol">● Fecha de nacimiento</p>
                <p class="texto-pol lista-pol">● Nacionalidad</p>
                <p class="texto-pol lista-pol">● Domicilio</p>
                <p class="texto-pol lista-pol">● Teléfono particular</p>
                <p class="texto-pol lista-pol">● Teléfono celular</p>
                <p class="texto-pol lista-pol">● Correo electrónico</p>
                <p class="texto-pol lista-pol">● Firma autógrafa</p>
                <p class="texto-pol lista-pol">● Firma electrónica</p>
                <p class="texto-pol lista-pol">● Edad</p>
                <p class="texto-pol lista-pol">● Fotografía</p>
                <p class="texto-pol lista-pol">● Estatura</p>
                <p class="texto-pol lista-pol">● Peso</p>
                <p class="texto-pol lista-pol">● Tipo de sangre</p>
                <p class="texto-pol lista-pol">● Huella dactilar</p>
                <p class="texto-pol lista-pol">● Puesto o cargo que desempeña</p>
                <p class="texto-pol lista-pol">● Domicilio de trabajo</p>
                <p class="texto-pol lista-pol">● Correo electrónico institucional</p>
                <p class="texto-pol lista-pol">● Teléfono institucional</p>
                <p class="texto-pol lista-pol">● Información generada durante los procesos de reclutamiento, selección y
                    contratación</p>
                <p class="texto-pol lista-pol">● Capacitación laboral</p>
                <p class="texto-pol lista-pol">● Entradas al país</p>
                <p class="texto-pol lista-pol">● Salidas del país</p>
                <p class="texto-pol lista-pol">● Cuentas bancarias</p>
                <p class="texto-pol lista-pol">● Datos de identificación</p>
                <p class="texto-pol lista-pol">● Datos de contacto</p>
                <p class="texto-pol lista-pol">● Datos biométricos</p>
                <p class="texto-pol lista-pol">● Datos laborales</p>
                <p class="texto-pol lista-pol">● Datos académicos</p>

                <p class="texto-pol"><b>¿Con quién compartimos su información personal y para qué fines?</b></p>

                <p class="texto-pol text4">Le informamos que sus datos personales son compartidos dentro y fuera del
                    país con las siguientes personas,
                    empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines:</p>

                <table>
                    <tr>
                        <th>Destinatario de los datos personales</th>
                        <th>Finalidad</th>
                        <th>Requiere del consentimiento</th>
                    </tr>
                    <tr>
                        <td>SECRETARIA DE SALUD</td>
                        <td>SANITARIA</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>GOBIERNO DE LA REPUBLICA</td>
                        <td>LA QUE REQUIERAN</td>
                        <td>No</td>
                    </tr>
                </table>

                <p class="texto-pol"><b>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su
                        uso?</b></p>

                <p class="texto-pol text5">Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué
                    los utilizamos y las condiciones del
                    uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información
                    personal en caso de
                    que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros
                    registros o bases
                    de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así
                    como oponerse
                    al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como
                    derechos
                    ARCO.</p>

                <p class="texto-pol text4">Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar
                    la solicitud respectiva a través del
                    siguiente medio:</p>

                <p class="texto-pol">correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol text4">Para conocer el procedimiento y requisitos para el ejercicio de los derechos
                    ARCO, ponemos a su disposición el
                    siguiente medio:</p>

                <p class="texto-pol">correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol text4">Los datos de contacto de la persona o departamento de datos personales, que
                    está a cargo de dar trámite a las
                    solicitudes de derechos ARCO, son los siguientes:</p>

                <p class="texto-pol lista2-pol">a) Nombre de la persona o departamento de datos personales: Francisco
                    Alberto Barragan Olivas</p>

                <p class="texto-pol lista2-pol">b) Domicilio: calle GARMENDIA 80 ENTRE VERACRUZ Y NAYARIT, colonia SAN
                    BENITO, ciudad Hermosillo,
                    municipio o delegación Hermosillo, c.p. 83190, en la entidad de Sonora, país México</p>

                <p class="texto-pol lista2-pol">c) Correo electrónico: lab_barragan@hotmail.com</p>

                <p class="texto-pol"><b>Usted puede revocar su consentimiento para el uso de sus datos personales</b>
                </p>

                <p class="texto-pol text5">Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para
                    el tratamiento de sus datos
                    personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos
                    atender su
                    solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal
                    requiramos seguir
                    tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la
                    revocación de su
                    consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la
                    conclusión de su
                    relación con nosotros.
                </p>

                <p class="texto-pol">Para revocar su consentimiento deberá presentar su solicitud a través del siguiente
                    medio:</p>

                <p class="texto-pol">correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol text4">Para conocer el procedimiento y requisitos para la revocación del
                    consentimiento, ponemos a su disposición el
                    siguiente medio:</p>

                <p class="texto-pol">correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol"><b>¿Cómo puede limitar el uso o divulgación de su información personal?</b></p>

                <p class="texto-pol text4">Con objeto de que usted pueda limitar el uso y divulgación de su información
                    personal, le ofrecemos los siguientes
                    medios:</p>

                <p class="texto-pol">correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol"><b>El uso de tecnologías de rastreo en nuestro portal de internet</b></p>

                <p class="texto-pol text5">Le informamos que en nuestra página de internet utilizamos cookies, web
                    beacons u otras tecnologías, a través de
                    las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un
                    mejor servicio y
                    experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas
                    tecnologías, los
                    utilizaremos para los siguientes fines:</p>

                <p class="texto-pol lista2-pol">Para confirmar y verificar identidad.</p>
                <p class="texto-pol lista2-pol">Para envío de cotizaciones.</p>

                <p class="texto-pol">Los datos personales que obtenemos de estas tecnologías de rastreo son los
                    siguientes:</p>

                <p class="texto-pol lista2-pol">Identificadores, nombre de usuario y contraseñas de una sesión</p>
                <p class="texto-pol lista2-pol">Idioma preferido por el usuario</p>
                <p class="texto-pol lista2-pol">Región en la que se encuentra el usuario</p>
                <p class="texto-pol lista2-pol">Tipo de navegador del usuario</p>
                <p class="texto-pol lista2-pol">Tipo de sistema operativo del usuario</p>
                <p class="texto-pol lista2-pol">Fecha y hora del inicio y final de una sesión de un usuario</p>
                <p class="texto-pol lista2-pol">Páginas web visitadas por un usuario</p>
                <p class="texto-pol lista2-pol">Búsquedas realizadas por un usuario</p>
                <p class="texto-pol lista2-pol">Publicidad revisada por un usuario</p>
                <p class="texto-pol lista2-pol">Listas y hábitos de consumo en páginas de compras</p>

                <p class="texto-pol"><b>¿Cómo puede conocer los cambios en este aviso de privacidad?</b></p>

                <p class="texto-pol text4">El presente aviso de privacidad puede sufrir modificaciones, cambios o
                    actualizaciones derivadas de nuevos
                    requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos;
                    de nuestras
                    prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

                <p class="texto-pol text4">Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir
                    el presente aviso de privacidad, a
                    través de: correo electrónico Lab_barragan@hotmail.com.</p>

                <p class="texto-pol text4">El procedimiento a través del cual se llevarán a cabo las notificaciones
                    sobre cambios o actualizaciones al presente
                    aviso de privacidad es el siguiente:</p>

                <p class="texto-pol">correo electrónico Lab_barragan@hotmail.com</p>

                <p class="texto-pol text6">Última actualización: 21/04/2021</p>














            </div>
        </div>

        <FooterSec :name="this.opc" />

    </div>
</template>

<script>

import HeaderB from '../HeaderB.vue';
import FooterSec from '../FooterSec.vue';

export default {
    name: 'PoliticaBarragan',
    data() {
        return {
            opcion: "Privacidad",
            opc: "barragan",
            name: "",
            phone: "",
            mail: "",
            message: ""

        }
    },
    components: {
        HeaderB,
        FooterSec
    },

    methods: {
        cambiarOpcion(opcion) {
            this.opcion = opcion;
        },

        redirect(direccion) {
            this.$router.push("/barragan")

            setTimeout(() => {
                window.location.href = `#${direccion}`
            }, 600);
        }
    },
}
</script>

<style scoped>
.selector {
    border: 1px solid white;
    border-radius: 3vw;
    text-align: center;
    margin: 0vw !important;
    padding: 0 !important;
}

.Bloque1 {
    display: block;
    width: 100%;
    height: 175.36231884057972VW;
    background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
    text-align: -webkit-center;
    padding-top: 0vw;
}

.B1F1 {
    width: 86.88405797101449VW;
    border-bottom: 1px solid white;
    padding-bottom: 4.830917874396135VW;
    margin-bottom: 6.038647342995169VW;
}

.B1F1C1 {
    display: flex;
    align-items: center;
}

.B1F1C1_C1 {
    margin-top: 4vw;
}

.B1F1C1_C1 img {
    width: 13.285024154589372VW;
    height: auto;
}

.B1F1C1_C2 {
    width: 32vw;
    height: 9vw;
    margin-left: 3.3816425120772946VW;
    padding-top: 5vw;
}

.B1F1C1_C2 p {
    color: white;
    font-size: 3.140096618357488VW;
    line-height: 4.270531400966184VW;
    font-weight: 700;
    text-align: left;
}

.B1F1C2 {
    display: none;
}

.B1F2C1 a {
    font-size: 3.140096618357488VW;
    padding: 4.1VW 29.9VW;
    border-radius: 8vw;
    margin-left: 0VW;
    color: white;
    background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
}

img.B1F2C2 {
    width: 99.903382VW;
    height: auto;
    margin-top: 13.3vw;
}

.B1F2C1_F1 p {
    color: #BBEFE3;
    font-size: 12.077294685990339VW;
    font-weight: 800;
    margin-bottom: 6.038647342995169VW;
    line-height: 7.971014492753622VW;
}

.B1F2C1_F1 span {
    font-size: 5.5VW;
}

.B1F2C1_F2 p {
    width: 61vw;
    color: white;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    margin-bottom: 7vw;
}

.politica-cont {
    padding: 33.625vw 11.56vw 10.625vw;
}

.titulo-pol {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 12.077vw;
}

p.texto-pol {
    font-size: 2.899vw;
    line-height: 179.5%;
    margin: 4.2552vw 14vw;
}

p.texto-pol.lista2-pol {
    margin: 0 15vw;
    line-height: 3vw;
}

table {
    background-color: transparent;
    margin-left: 6vw;
}

th {
    width: 37vw;
    text-align: left;
    border: 1px solid black;
    font-size: 2.042vw;
    line-height: 179.5%;
    color: black;
}

td {
    text-align: left;
    border: 1px solid black;
    font-size: 2.052vw;
    line-height: 179.5%;
    color: black;
}




.P1 {
    /*text-align: -webkit-center;*/
}


@media (min-width: 768px) {

    .Bloque1 {
        background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
        display: block;
        height: 6.90625VW;
        width: 100%;
        padding-top: 0vw;
    }

    .B1F1 {
        display: flex;
        place-content: center;
        margin: 0vw 6vw;
        padding-bottom: 1vw;
        border-bottom: 1px solid white;
    }

    .B1F1C1 {

        margin-right: 33vw;
    }

    .B1F1C1_C1 {
        margin-top: 0.1VW;
        margin-right: 1.6145833333333335VW;
    }

    .B1F1C1_C1 img {
        width: 4.53125VW;
        height: auto;
    }

    .B1F1C1_C2 {
        width: 8vw;
        height: 1.5vw;
        padding-top: 0.5vw;
        text-transform: uppercase;
        margin-left: 0vw;
    }

    .B1F1C1_C2 p {
        font-size: 0.78125VW;
        line-height: 0.9208333333333334VW;
        text-align: left;
    }

    .B1F1C2 {
        display: flex;
        align-items: center;
    }

    .B1F1C2_C1 ul {
        display: flex;
        color: white;
        list-style: none;
        font-weight: 500;
        font-size: 0.6770833333333334VW;
        margin-bottom: 0vw;
    }

    .B1F1C2_C1 li {
        /* margin: 0vw 1.5vw; */
        cursor: pointer;
        align-self: center;
        position: relative;
    }

    .B1F1C2_C1 li {
        cursor: pointer;
        align-self: center;
        position: relative;
    }

    .B1F1C2_C1>ul>li {
        cursor: pointer;
        align-self: center;
    }

    .B1F1C2_C1>ul>li:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .B1F1C2_C1>ul>li:first-of-type:hover {
        text-decoration: underline;
    }

    .B1F1C2_C1>ul>li {
        width: 5.989583333333334vw;
        height: 1.8229166666666667vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .B1F1C2_C1>ul>li>a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .B1F1C2_C1 a {
        color: white;
        white-space: nowrap;
    }

    .B1F2 {
        display: flex;
    }

    .B1F2C1 {
        width: 52vw;
        height: 10vw;
        margin-top: 4.739583VW;
    }

    .B1F2C1_F1 {
        margin-left: 13vw;
    }

    .B1F2C1_F1 p {
        color: #BBEFE3;
        font-weight: 800;
        font-size: 5.208333333333334VW;
        line-height: 3VW;
        margin-bottom: 2VW;
        text-align: left;
    }

    .B1F2C1_F1 span {
        font-size: 2.083333333333333VW;
    }

    .B1F2C1_F2 {
        width: 20vw;
        text-align: left;
        margin-left: -6vw;
    }

    .B1F2C1_F2 p {
        width: 20vw;
        color: white;
        font-weight: 400;
        font-size: 0.78125VW;
        line-height: 1.1213541666666667VW;
        margin-bottom: 3vw;
    }

    .B1F2C1 a {
        font-size: 0.6770833333333334VW;
        color: white;
        padding: 1.1VW 3.9VW;
        background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
        border-radius: 8vw;
        margin-left: -16VW;
        cursor: pointer;
    }

    .B1F2C2 {
        width: 35.041667VW !important;
        height: auto !important;
        margin-left: 0vw;
        margin-bottom: 5vw;
        margin-top: -2.78vw !important;
    }

    .P1 h1 {
        font-size: 1.8vw;
        font-weight: 900;
        color: black;
    }

    .P1 p {
        width: 46vw;
        font-size: 1vw;
        color: black;
        font-weight: 600;
    }

    .politica-cont {
        padding: 10.625vw 21.042vw;
    }

    p.texto-pol {
        width: 47vw;
        font-size: 1.042vw;
        line-height: 179.5%;
        color: black;
        margin: 1vw 6vw;
    }

    .text3 {
        width: 55vw !important;
    }

    .text4 {
        width: 51vw !important;
    }

    .text5 {
        width: 49vw !important;
    }

    .text6 {
        text-align: right;
        margin: 0vw 9vw !important;
    }

    .titulo-pol {
        text-align: center;
        font-size: 2.344vw;
        line-height: 2.708vw;
        color: black;
        font-family: 'TTSN-Regular';
        margin-bottom: 3vw;
    }

    p.texto-pol.lista-pol {
        margin: 0 7vw;
    }

    p.texto-pol.lista2-pol {
        margin: 0 7vw;
        line-height: 1vw;
    }

    th {
        width: 17vw;
        text-align: left;
        border: 1px solid black;
        font-size: 1.042vw;
        line-height: 179.5%;
        color: black;
    }

    td {
        text-align: left;
        border: 1px solid black;
        font-size: 1.052vw;
        line-height: 179.5%;
        color: black;
    }

    .wrapper {
        box-sizing: border-box;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s linear;
        position: absolute;
        background-image: linear-gradient(0deg, #183B68 81.32%, rgba(48, 117, 206, 0.00) 100%);

        width: 20.885416666666668vw;
        border-radius: 0 0 1.0416666666666665vw 1.0416666666666665vw;
        top: 100%;
        left: -11.854166666666668vw;
    }

    .wrapper.extended {
        grid-template-rows: 1fr;
    }

    .wrapper * {
        box-sizing: border-box;
    }

    .wrapper>div {
        overflow: hidden;
        padding: 0 2.1875vw;
    }

    .wrapper h2 {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 1.25vw;
        line-height: 100%;
        margin: 5vw 0 0.8333333333333334vw;
    }

    .wrapper h2+a {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(0deg, #4F8B77 0%, #6FBA91 100%);
        color: #FFF;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;

        font-size: 0.9895833333333333vw;
        line-height: normal;
        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;


        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;
    }

    .wrapper ol {
        padding: 0 0 0 1.0416666666666665vw;
    }

    .wrapper li {
        margin: 0;
        text-align: start;
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;

        font-size: 0.8333333333333334vw;
        line-height: 150%;
    }

    .wrapper li a {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        display: inline !important;
        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
    }

    .wrapper h3 {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
        margin: 1.6666666666666667vw 0 1.5625vw;
    }

    .wrapper button {
        border: 0;
        padding: 0;
        background-color: transparent;
        color: #6FBA91;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;

        font-size: 0.7291666666666666vw;
        line-height: 171.429%;
        text-decoration-line: underline;
        margin: 0 0 0.9895833333333333vw;
    }

}
</style>
